import { IconConfigFragment } from '@codegen/cmsUtils';
import CoreIcon, { IconProps as CoreIconProps } from '@ui-v2/core/Icon/Icon';
import useGetIcon from '@web/context/hooks/useGetIcon';

export type IconProps = Omit<CoreIconProps, 'icon'> & {
  type?: keyof IconConfigFragment;
};

const Icon = ({ type, ...props }: IconProps) => {
  const getIcon = useGetIcon();
  const resolvedIcon = type ? getIcon(type) : null;

  return <CoreIcon icon={resolvedIcon} {...props} />;
};

export default Icon;
